/* eslint-disable import/prefer-default-export */
/* eslint-env browser */
import { setMetaThemeColor } from './helpers';

const themes = ['blue', 'purple', 'cyan', 'turquoise', 'green', 'yellow', 'red', 'magenta', 'orange', 'dark-blue', 'dark-purple', 'dark-cyan', 'dark-turquoise', 'dark-green', 'dark-yellow', 'dark-red', 'dark-magenta', 'dark-orange'];
const scales = ['default', 'medium', 'wide', 'reset'];

const persistSelection = (key: string, value: string | null): void => {
  if (value == null) {
    window.localStorage.removeItem(key);
  } else {
    window.localStorage.setItem(key, value);
  }
};

const getPersisted = (): (string | null)[] => {
  const storage = window.localStorage;
  return [
    storage.getItem('atom-picker-theme'),
    storage.getItem('atom-picker-style'),
    storage.getItem('atom-picker-position'),
    storage.getItem('atom-picker-scale'),
  ];
};

const clearThemes = (item: string): void => {
  document.querySelector('body')?.classList.remove(`theme--${item}`);
};

const setTheme = (theme: string): void => {
  themes.forEach(clearThemes);
  if (theme !== 'theme--blue') {
    document.querySelector('body')?.classList.add(theme);
  }
  persistSelection('atom-picker-theme', theme);
};

const setThemeStyle = (): void => {
  const body = document.querySelector('body');
  const content = document.getElementById('content');
  const aside = document.getElementById('aside');
  const themeNormal = 'theme--normal';
  const themeDark = 'theme--dark';
  body?.classList.remove('body--scrollbar-dark');
  if (!content?.classList.contains(themeNormal)) {
    body?.classList.toggle(themeDark);
  }
  content?.classList.remove(themeNormal);
  if (aside) {
    aside.classList.remove(themeNormal);
  }
  persistSelection('atom-picker-style', body?.classList.contains(themeDark) ? themeDark : null);
};

const togglePosition = (): void => {
  const picker = document.getElementById('atom-picker');
  const top = 'atom-picker--at-top';
  picker?.classList.toggle(top);
  persistSelection('atom-picker-position', picker?.classList.contains(top) ? top : null);
};

const clearScales = (item: string): void => {
  document.querySelector('body')?.classList.remove(`view--${item}`);
};

const setScale = (scale: string): void => {
  scales.forEach(clearScales);
  if (scale !== 'reset') {
    document.querySelector('body')?.classList.add(scale);
    persistSelection('atom-picker-scale', scale);
  } else {
    themes.forEach(clearThemes);
    document.querySelector('body')?.classList.add('theme--dark');
    document.querySelector('body')?.classList.add('body--scrollbar-dark');
    document.getElementById('content')?.classList.add('theme--normal');
    const aside = document.getElementById('aside');
    if (aside) {
      aside.classList.add('theme--normal');
    }
    persistSelection('atom-picker-theme', null);
    persistSelection('atom-picker-scale', null);
    persistSelection('atom-picker-position', null);
    persistSelection('atom-picker-style', null);
    setMetaThemeColor();
  }
};

const setAtomPicker = (): void => {
  const [persistedTheme, persistedStyle, persistedTop, persistedScale] = getPersisted();
  if (persistedTheme) {
    setTheme(persistedTheme);
  }
  if (persistedStyle) {
    setThemeStyle();
  }
  if (persistedTop) {
    togglePosition();
  }
  if (persistedScale) {
    setScale(persistedScale);
  }

  const toggle = document.querySelector('.atom-picker__scale__item__position-toggle');
  if (toggle) {
    toggle.addEventListener('click', () => {
      togglePosition();
    });
  }

  const themeStyle = document.querySelector('.atom-picker__scale__item__theme-style');
  if (themeStyle) {
    themeStyle.addEventListener('click', () => {
      setThemeStyle();
      setMetaThemeColor();
    });
  }

  scales.forEach((scale) => {
    const element = document.querySelector(`.atom-picker__scale__item__${scale}`);
    if (element) {
      element.addEventListener('click', () => {
        setScale(scale === 'reset' ? 'reset' : `view--${scale}`);
      });
    }
  });

  themes.forEach((theme) => {
    const element = document.querySelector(`.atom-picker__color__item.theme--${theme}`);
    if (element) {
      element.addEventListener('click', () => {
        setTheme(`theme--${theme}`);
        setMetaThemeColor();
      });
    }
  });
};

export {
  setAtomPicker,
};
