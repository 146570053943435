/* eslint-env browser */
import { copyToClipboard } from './helpers';

const copyHexCode = (): void => {
  const ctc = document.querySelectorAll('.copy-to-clipboard');
  const colorElements = document.querySelectorAll('.copy-to-clipboard__color');
  ctc.forEach((button) => {
    button.addEventListener('click', () => {
      let color = button.parentElement?.querySelector('.copy-to-clipboard__color');
      if(color) {
        let computedStyle = getComputedStyle(color).getPropertyValue('background-color');
        let hexColor = rgbToHex(computedStyle);
        console.log(hexColor);
        copyToClipboard(hexColor.replace(/"/g, ''));
      }
    });
  });
};

function rgbToHex(rgb) {
  // Extract the individual RGB components
  var rgbArray = rgb.match(/\d+/g);

  // Convert each component to hex and concatenate
  var hex = "#" + rgbArray.map(function (value) {
    var hexValue = parseInt(value).toString(16);
    return hexValue.length === 1 ? "0" + hexValue : hexValue;
  }).join('');

  return hex;
}

export default copyHexCode;
