/* eslint-env browser */
// import React from 'react';
// import ReactDOM from 'react-dom';
import ready from '../components/ready';
import { setSubNavToggle, setCopyToClipboard, setSearchToggle } from '../components/nav';
import {
  setAtomPicker,
} from '../components/atom-picker';
import copyHexCode from '../components/copy-hex-code';
import { showModal, hideModal } from '../components/modal-view';
import { closeAllContentTogglesInToolbar, setOverflow, setToolbar } from '../components/toolbar';
import { setSelectionListOverlays } from '../components/selection-list';
import { setInlineSelectLabel } from '../components/form';
import { setHorizontalScroll, setScrollbarWidthVariable } from '../components/helpers';
import { setTruncate } from '../components/truncator';

let windowInnerWidth = 0;

ready(() => {
  if (window.top === window.self && document.querySelector('.atom-picker')) {
    setAtomPicker();
  }

  const inlineSelectLabels = document.querySelectorAll('.form__field--inline-label--select');
  inlineSelectLabels.forEach((select) => {
    setInlineSelectLabel(select);
  });

  const navs = document.querySelectorAll('.nav');
  navs.forEach((nav) => {
    const navSearch = nav.querySelector('.nav__search');
    const searchBtn = nav.querySelector('.nav__search-toggle');
    if (navSearch && searchBtn) {
      setSearchToggle(navSearch, searchBtn);
    }
  });

  // Find alle copy-to-clipboard elementer
  const ctc = document.querySelectorAll('.copy-to-clipboard');
  ctc.forEach((element) => setCopyToClipboard(element as HTMLElement, document.querySelector('html')?.getAttribute('lang') === 'en' ? 'Hexcode copied!' : 'Hexkode kopieret!'));
  copyHexCode();
  setSelectionListOverlays();


  // Horizontal scroll
  const horizontalScroll = document.querySelectorAll('.horizontal-scroll');
  horizontalScroll.forEach((hs) => {
    setHorizontalScroll(hs as HTMLElement);
  });

  const subNavBtns = document.querySelectorAll('.sub-nav > button.nav__item');
  subNavBtns.forEach((button) => {
    setSubNavToggle(subNavBtns, button); // Vil normalt blive lavet med et React-component
  });

  const modalsBtns = document.querySelectorAll('[data-modal]');
  modalsBtns.forEach((button) => {
    const modalId = button.getAttribute('data-modal') as string;
    const modal = document.getElementById(modalId);
    const closeBtn = modal?.querySelector('.modal-view__close');
    closeBtn?.addEventListener('click', () => {
      hideModal(modalId, button);
    });
    button.addEventListener('click', () => {
      showModal(modalId, button);
    });
  });

  const toolbars = document.querySelectorAll('.toolbar');
  toolbars.forEach((toolbar) => setToolbar(toolbar as HTMLElement,
    (tb: HTMLElement) => {
      closeAllContentTogglesInToolbar(tb);
    }, false));

  const truncators = document.querySelectorAll('.truncator');
  truncators.forEach((truncator) => setTruncate(truncator as HTMLElement));

  document.documentElement.style.setProperty('--window-inner-height', `${window.innerHeight}px`);
  setScrollbarWidthVariable();

  window.addEventListener('load', () => {
    windowInnerWidth = window.innerWidth;
  });

  window.addEventListener('resize', () => {
    if (windowInnerWidth > 0 && window.innerWidth !== windowInnerWidth) {
      windowInnerWidth = window.innerWidth;
      inlineSelectLabels.forEach((select) => {
        setInlineSelectLabel(select);
      });

      toolbars.forEach((toolbar) => {
        closeAllContentTogglesInToolbar(toolbar as HTMLElement);
        setOverflow(toolbar);
      });

      setScrollbarWidthVariable();
      horizontalScroll.forEach((hs) => {
        (hs as HTMLElement).classList.remove('horizontal-scroll--no-overflow');
        setHorizontalScroll(hs as HTMLElement);
      });
    }

    document.documentElement.style.setProperty('--window-inner-height', `${window.innerHeight}px`);
  });
});
