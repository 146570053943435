/* eslint-env browser */
import { isElementWithClassNameInEventPath } from './helpers';

const removeOverlay = (containers: NodeListOf<Element>): void => {
  containers.forEach((container) => {
    const lists = container.querySelectorAll('.selection-list');
    lists.forEach((list) => {
      list.classList.remove('selection-list--toggled');
    });
  });
};

const setSelectionListOverlays = (): setSelectionListOverlaysCleanUp => {
  const cleanUp: CleanUpPair[] = [];
  const containers = document.querySelectorAll('.selection-list-overlay-container');
  containers.forEach((container) => {
    const btn = container.querySelector('button') as HTMLElement; // Assumes only one button pr container
    const clickEvent = () => {
      removeOverlay(containers);
      const sibling = btn.nextSibling as HTMLElement;
      if (sibling) {
        sibling.classList.add('selection-list--toggled');
      }
    };
    cleanUp.push({
      element: btn,
      clickEvent,
    });

    btn.addEventListener('click', clickEvent);
  });

  const bodyClickEvent = (e: Event): void => {
    if (!(e.target as HTMLElement).classList.contains('selection-list--overlay')
      && !isElementWithClassNameInEventPath(e as MouseEvent, 'selection-list-overlay-container')) {
      removeOverlay(containers);
    }
  };
  document.querySelector('body')?.addEventListener('click', bodyClickEvent);

  return {
    pairs: cleanUp,
    element: document.querySelector('body'),
    clickEvent: bodyClickEvent,
  };
};

export {
  setSelectionListOverlays,
  removeOverlay,
};
